import React from "react";

import MarketplaceCartMaker from "~/components/Appointments/CartMaker/MarketplaceCartMaker";
import PrivateRoute from "~/components/Authentication/PrivateRoute";

type LaboratoryPageProps = {
  id: string;
};

const CheckoutBuilderEdition = ({ id }: LaboratoryPageProps) => {
  return (
    <PrivateRoute>
      <MarketplaceCartMaker id={id} />
    </PrivateRoute>
  );
};

export default CheckoutBuilderEdition;
