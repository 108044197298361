import React, { useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { SAutocomplete, SDeleteForeverIcon } from "~/styles/Layout/Selectors";
import { Locale } from "~/utils/interfaces/Locale";

interface Props {
  query: string;
  country?: Locale;
  selectedItems: any;
  fetchedItems: any;
  setSelectedItems: any;
  setQuery: any;
  refetching?: boolean;
  onSubmit: () => void;
}

const SelectItems = (props: Props): JSX.Element => {
  const [itemOrPackId, setItemOrPackId] = useState<string>("");
  const [localQuery, setLocalQuery] = useState<string>(props.query);
  const deleteSelected = (id: string) => {
    props.setSelectedItems(props.selectedItems.filter((item: any) => item.id !== id));
  };

  useEffect(() => {
    const item = props.fetchedItems.find((item: any) => item.id === itemOrPackId);
    if (item) {
      props.setSelectedItems((prev: any) => [...prev, { ...item, amount: 1 }]);
    }
  }, [itemOrPackId]);

  return (
    <div className="space-y-6">
      <div className="flex items-end">
        <SAutocomplete
          freeSolo
          disableCloseOnSelect
          className="max-w-md"
          options={props.fetchedItems}
          getOptionLabel={(option) => {
            const preName = option.rawData?.code || option.code ? `${option.rawData?.code || option.code} - ` : "";
            return `${preName}${option.name}`;
          }}
          getOptionDisabled={(option) => props.selectedItems.some((item) => item.id === option.id)}
          inputValue={localQuery}
          onChange={(_, newValue: any) => {
            if (newValue) setItemOrPackId(newValue.id as string);
          }}
          onInputChange={(_, newValue: string, reason) => {
            if (reason === "reset") return;
            setLocalQuery(newValue);
            props.setQuery(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText=""
              size="small"
              variant="outlined"
              placeholder="Busca tus servicios acá"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {props.refetching ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                      />
                    ) : typeof props.refetching === "boolean" && props.fetchedItems.length === 0 ? (
                      <p className="text-sm text-gray-300 mb-0">0 Resultados</p>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="space">
        <p className="!font-bold text-sm">Servicios agregados ({props.selectedItems?.length})</p>
        <div>
          {props.selectedItems?.map((item, idx) => {
            return (
              <div
                className="flex items-center mb-2 gap-x-3"
                key={idx}
              >
                <SDeleteForeverIcon
                  color="secondary"
                  onClick={() => deleteSelected(item.id)}
                />
                <p className="w-3/4 mb-0">
                  {item.rawData?.code || item.code ? `${item.rawData?.code || item.code} - ` : null}
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectItems;
